var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__body" }, [
            _c("form", { staticClass: "kt-form kt-form--fit kt-margin-b-20" }, [
              _c("div", { staticClass: "row kt-margin-b-20 pl-3 pr-3" }, [
                _c("div", { staticClass: "kt-widget12" }, [
                  _c("div", { staticClass: "kt-widget12__content" }, [
                    _c("div", { staticClass: "kt-widget12__item" }, [
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c(
                          "span",
                          { staticClass: "kt-widget12__desc kt-font-danger" },
                          [_vm._v("Total Delinquent")]
                        ),
                        _c(
                          "span",
                          { staticClass: "kt-widget12__value kt-font-danger" },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.formatCurrency(_vm.totalDelinquentCost)
                              )
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c(
                          "span",
                          { staticClass: "kt-widget12__desc kt-font-success" },
                          [_vm._v("Total Allocated")]
                        ),
                        _c(
                          "span",
                          { staticClass: "kt-widget12__value kt-font-success" },
                          [
                            _vm._v(
                              _vm._s(_vm.formatCurrency(_vm.totalAllocatedCost))
                            ),
                          ]
                        ),
                      ]),
                      _c("div", { staticClass: "kt-widget12__info" }, [
                        _c(
                          "span",
                          { staticClass: "kt-widget12__desc kt-font-primary" },
                          [_vm._v("Total Dispersed")]
                        ),
                        _c(
                          "span",
                          { staticClass: "kt-widget12__value kt-font-primary" },
                          [_vm._v(_vm._s(_vm.formatCurrency(0)))]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row kt-margin-b-20" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-3 kt-margin-b-10-tablet-and-mobile" },
                  [
                    _c("label", [_vm._v("Subscription Year:")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search.schoolYear,
                            expression: "search.schoolYear",
                          },
                        ],
                        staticClass: "form-control kt-input",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.search,
                              "schoolYear",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.schoolYears, function (year) {
                        return _c(
                          "option",
                          { key: `option_${year}`, domProps: { value: year } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(year) +
                                " - " +
                                _vm._s(parseInt(year, 10) + 1) +
                                " "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-3 kt-margin-b-10-tablet-and-mobile" },
                  [
                    _c("label", [_vm._v("Account Status:")]),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.search.status,
                            expression: "search.status",
                          },
                        ],
                        staticClass: "form-control kt-input",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.search,
                              "status",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      _vm._l(_vm.accountStatus, function (ss) {
                        return _c(
                          "option",
                          {
                            key: `option_${ss}`,
                            domProps: {
                              value: ss,
                              selected: ss == _vm.search.status,
                            },
                          },
                          [_vm._v(" " + _vm._s(ss || "Select") + " ")]
                        )
                      }),
                      0
                    ),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-3 kt-margin-b-10-tablet-and-mobile" },
                  [
                    _c("label", [_vm._v("School:")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.school,
                          expression: "search.school",
                        },
                      ],
                      staticClass: "form-control kt-input",
                      attrs: { type: "text", placeholder: "Westinghouse" },
                      domProps: { value: _vm.search.school },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.search, "school", $event.target.value)
                        },
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "col-lg-3 kt-margin-b-10-tablet-and-mobile" },
                  [
                    _c("label", [_vm._v("Purchase Order:")]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search.purchaseOrder,
                          expression: "search.purchaseOrder",
                        },
                      ],
                      staticClass: "form-control kt-input",
                      attrs: { type: "text", placeholder: "PO2353535" },
                      domProps: { value: _vm.search.purchaseOrder },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.search,
                            "purchaseOrder",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]
                ),
              ]),
              false ? _c("div", { staticClass: "row" }, [_vm._m(1)]) : _vm._e(),
            ]),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped table-hover" }, [
                _vm._m(2),
                _c(
                  "tbody",
                  _vm._l(_vm.schools, function (school, idx) {
                    return _c(
                      "tr",
                      { key: `${idx}_${school.externalSchoolId}` },
                      [
                        _c("td", [
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-badge kt-badge--lg kt-badge--rounded kt-font-bolder",
                              class: {
                                "kt-badge--danger":
                                  school.status == "Delinquent",
                                "kt-badge--warning":
                                  school.status == "Allocated",
                                "kt-badge--primary":
                                  school.status == "Dispersed",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(school.status.substr(0, 1)) + " "
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "kt-font-bold",
                                staticStyle: { "min-width": "200px" },
                                attrs: {
                                  to: {
                                    name: "CreateOrEditSchool",
                                    params: {
                                      externalSchoolId: school.externalSchoolId,
                                    },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(school.schoolName) + " ")]
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(school.status) +
                                " - " +
                                _vm._s(school.externalSchoolId) +
                                " "
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          { staticClass: "kt-font-bolder" },
                          [
                            school.status == "Delinquent"
                              ? [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded",
                                    },
                                    [_vm._v("MISSING")]
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(school.purchaseOrderNumber) +
                                      " "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "kt-subheader kt-grid__item",
        attrs: { id: "kt_subheader" },
      },
      [
        _c("div", { staticClass: "kt-container kt-container--fluid" }, [
          _c("div", { staticClass: "kt-subheader__main" }, [
            _c("h3", { staticClass: "kt-subheader__title" }, [
              _vm._v(" School Purchases "),
            ]),
          ]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-lg-12" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-brand--icon mr-3",
          attrs: { id: "kt_search" },
        },
        [
          _c("span", [
            _c("i", { staticClass: "la la-search" }),
            _c("span", [_vm._v("Search")]),
          ]),
        ]
      ),
      _c(
        "button",
        {
          staticClass: "btn btn-secondary btn-secondary--icon",
          attrs: { id: "kt_reset" },
        },
        [
          _c("span", [
            _c("i", { staticClass: "la la-close" }),
            _c("span", [_vm._v("Reset")]),
          ]),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Status ")]),
        _c("th", [_vm._v(" School Name ")]),
        _c("th", [_vm._v(" PO # ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }