<template>
<div>
    <div id="kt_subheader" class="kt-subheader kt-grid__item">
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <h3 class="kt-subheader__title">
                    School Purchases
                </h3>
            </div>
        </div>
    </div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <div class="kt-portlet">
            <div class="kt-portlet__body">
                <form class="kt-form kt-form--fit kt-margin-b-20">
                    <div class="row kt-margin-b-20 pl-3 pr-3">
                        <div class="kt-widget12">
                            <div class="kt-widget12__content">
                                <div class="kt-widget12__item">
                                    <div class="kt-widget12__info">
                                        <span class="kt-widget12__desc kt-font-danger">Total Delinquent</span>
                                        <span class="kt-widget12__value kt-font-danger">{{
                                            formatCurrency(totalDelinquentCost) }}</span>
                                    </div>

                                    <div class="kt-widget12__info">
                                        <span class="kt-widget12__desc kt-font-success">Total Allocated</span>
                                        <span class="kt-widget12__value kt-font-success">{{
                                            formatCurrency(totalAllocatedCost) }}</span>
                                    </div>

                                    <div class="kt-widget12__info">
                                        <span class="kt-widget12__desc kt-font-primary">Total Dispersed</span>
                                        <span class="kt-widget12__value kt-font-primary">{{ formatCurrency(0) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row kt-margin-b-20">
                        <div class="col-lg-3 kt-margin-b-10-tablet-and-mobile">
                            <label>Subscription Year:</label>
                            <select v-model="search.schoolYear" class="form-control kt-input">
                                <option
                                    v-for="year in schoolYears"
                                    :key="`option_${year}`"
                                    :value="year"
                                >
                                    {{ year }} - {{ parseInt(year, 10) + 1 }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3 kt-margin-b-10-tablet-and-mobile">
                            <label>Account Status:</label>
                            <select v-model="search.status" class="form-control kt-input">
                                <option
                                    v-for="ss in accountStatus"
                                    :key="`option_${ss}`"
                                    :value="ss"
                                    :selected="ss == search.status"
                                >
                                    {{ ss || 'Select' }}
                                </option>
                            </select>
                        </div>
                        <div class="col-lg-3 kt-margin-b-10-tablet-and-mobile">
                            <label>School:</label>
                            <input
                                v-model="search.school"
                                type="text"
                                class="form-control kt-input"
                                placeholder="Westinghouse"
                            >
                        </div>
                        <div class="col-lg-3 kt-margin-b-10-tablet-and-mobile">
                            <label>Purchase Order:</label>
                            <input
                                v-model="search.purchaseOrder"
                                type="text"
                                class="form-control kt-input"
                                placeholder="PO2353535"
                            >
                        </div>
                    </div>

                    <div v-if="false" class="row">
                        <div class="col-lg-12">
                            <button id="kt_search" class="btn btn-primary btn-brand--icon mr-3">
                                <span>
                                    <i class="la la-search" />
                                    <span>Search</span>
                                </span>
                            </button>

                            <button id="kt_reset" class="btn btn-secondary btn-secondary--icon">
                                <span>
                                    <i class="la la-close" />
                                    <span>Reset</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </form>

                <div class="table-responsive">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>
                                    Status
                                </th>
                                <th>
                                    School Name
                                </th>
                                <th>
                                    PO #
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(school, idx) in schools" :key="`${idx}_${school.externalSchoolId}`">
                                <td>
                                    <span
                                        class="kt-badge kt-badge--lg kt-badge--rounded kt-font-bolder"
                                        :class="{
                                            'kt-badge--danger': school.status == 'Delinquent',
                                            'kt-badge--warning': school.status == 'Allocated',
                                            'kt-badge--primary': school.status == 'Dispersed',
                                        }"
                                    >
                                        {{ school.status.substr(0, 1) }}
                                    </span>
                                </td>
                                <td>
                                    <router-link
                                        :to="{ name: 'CreateOrEditSchool', params: { externalSchoolId: school.externalSchoolId } }"
                                        class="kt-font-bold"
                                        style="min-width: 200px"
                                    >
                                        {{ school.schoolName }}
                                    </router-link>
                                    <br>
                                    {{ school.status }}
                                    -
                                    {{ school.externalSchoolId }}
                                </td>
                                <td class="kt-font-bolder">
                                    <template v-if="school.status == 'Delinquent'">
                                        <span
                                            class="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                                        >MISSING</span>
                                    </template>
                                    <template v-else>
                                        {{ school.purchaseOrderNumber }}
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import { mapState } from 'vuex';
import moment from 'moment';
import Types from '../../../store/Types';

export default {
    name: 'SchoolPurchases',
    data() {
        const year = new Date().getFullYear();
        return {
            search: {
                school: '',
                purchaseOrder: '',
                schoolYear: year,
                status: null,
            },
            schoolYears: [
                year - 3,
                year - 2,
                year - 1,
                year,
                year + 1,
            ],
            accountStatus: [null, 'Delinquent', 'Allocated', 'Dispersed'],
        };
    },
    computed: {
        ...mapState({
            transactions: (state) => state.database.transactions,
            schoolsInDB: (state) => state.database.schools,
            deviceType: (state) => state.deviceType,
        }),
        schools() {
            const { search } = this;
            return this.schoolsInDB.map((s) => {
                const school = s;
                school.age = moment(`${s.created}`).fromNow();
                school.sort = `${s.schoolName}`;
                school.search = `${s.externalSchoolId} ${s.schoolName}`.toLowerCase();

                const subscription = s.subscriptions.find((ss) => ss.schoolYear == search.schoolYear);
                school.purchaseOrderNumber = subscription && subscription.purchaseOrderNumber || '';
                school.schoolYear = subscription && subscription.schoolYear || null;

                school.status = 'Delinquent';
                if (school.purchaseOrderNumber) {
                    school.status = 'Allocated';
                }

                return school;
            })
                .filter((t) => {
                    if (t.deleted) return false;
                    if (!t.schoolYear) return false; // year search
                    const searchString = (search.school || '').toLowerCase();
                    if (searchString) { // school search
                        if (t.search.indexOf(searchString) == -1) return false;
                    }
                    const purchaseOrder = (search.purchaseOrder || '').toLowerCase();
                    if (purchaseOrder) { // purchaseOrder search
                        if (t.purchaseOrderNumber.toLowerCase().indexOf(purchaseOrder) == -1) return false;
                    }
                    const { status } = search;
                    if (status) {
                        if (t.status !== status) return false;
                    }
                    return true;
                })
                .filter((t) => { // PO
                    if (!t.schoolYear) return false;
                    const searchString = (search.school || '').toLowerCase();
                    if (!searchString) return true;
                    if (t.search.indexOf(searchString) == -1) return false;
                    return true;
                })
                .sort((a, b) => {
                    if (a.sort < b.sort) return -1;
                    if (a.sort > b.sort) return 1;
                    return 0;
                });
        },
        totalDelinquentCost() {
            // const { schools } = this;
            // const total = schools
            //     .filter((s) => s.status == 'Delinquent')
            //     .map((s) => s.cost);
            // if (total.length == 0) return 0;
            // return total.reduce((a, b) => a + b);
            return 0;
        },
        totalAllocatedCost() {
            // const { schools } = this;
            // const total = schools
            //     .filter((s) => s.status == 'Allocated')
            //     .map((s) => s.cost);
            // if (total.length == 0) return 0;
            // return total.reduce((a, b) => a + b);
            return 0;
        },
    },
    mounted() {
        if (this.schools.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_SCHOOLS);
        }
        if (this.transactions.length == 0) {
            this.$store.dispatch(Types.actions.REFRESH_ADMIN_TRANSACTIONS);
        }
    },
    methods: {
        editSchool(school) {
            const { externalSchoolId } = school;
            this.$router.push({ name: 'CreateOrEditSchool', params: { externalSchoolId } });
        },
    },
};
</script>

<style scoped>
tbody tr td {
    vertical-align: middle;
}
</style>
